/* eslint-disable no-new */
import '@babel/polyfill';
import '../sass/styles.scss';

import ModuleLoader from './module-loader';

document.addEventListener('DOMContentLoaded', () => {
  ModuleLoader.load();
  const html = document.querySelector('html');
  html.classList.remove('no-js');
  html.classList.add('js');
});
