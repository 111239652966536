/**
 * Logger
 * Will be automatically enabled in development mode, and disabled in production mode
 * Can be enabled in production mode via ?logger=enabled
 */
class Logger {
  constructor() {
    if (!Function.prototype.bind) {
      Function.prototype.bind = function (oThis) {
        /* jshint newcap: false */
        if (typeof this !== 'function') {
          // closest thing possible to the ECMAScript 5
          // internal IsCallable function
          throw new TypeError('Function.prototype.bind - what is trying to be bound is not callable');
        }

        const aArgs = Array.prototype.slice.call(arguments, 1);
        const fToBind = this;
        const fNOP = function () {
        };
        const fBound = function () {
          return fToBind.apply(this instanceof fNOP && oThis ? this : oThis,
            aArgs.concat(Array.prototype.slice.call(arguments)));
        };

        fNOP.prototype = this.prototype;
        fBound.prototype = new fNOP();

        return fBound;
      };
    }

    this.L = null;
    this.enabled = false;
    this.prefix = null;

    this.setup();
  }

  isNative(fn) {
    // IE11  doesn't have `[native code]` in the toString of console.log, but it does have 'safeAssert' which seems like a reasonable thing to test...
    return /^\s*function[^{]+{\s*\[native code\]\s*}\s*$|safeAssert/.test(fn);
  }

  setup() {
    if (typeof window.console !== 'undefined' && typeof window.console.log !== 'undefined' && this.isNative(window.console.log) === true) {
      this.L = window.console;
      // fix ie8/9 - bind/apply

      if (typeof Function.prototype.bind !== 'undefined' && typeof console.log === 'object') {
        if (typeof Array.prototype.forEach === 'undefined') { // IE8 Array forEach
          Array.prototype.forEach = function (fn, scope) {
            let i; const
              len = this.length;
            for (i = 0; i < len; ++i) {
              fn.call(scope || this, this[i], i, this);
            }
          };
        }
        ['log', 'info', 'warn', 'error', 'assert', 'dir', 'clear', 'profile', 'profileEnd'].forEach(function (method) {
          console[method] = this.bind(console[method], console);
        }, Function.prototype.call);
      }
    } else {
      this.L = {
        fake: true,
        log() {
        },
        info() {
        },
        warn() {
        },
        error() {
        },
      };
      window.console = this.L;
    }

    if (process.env.NODE_ENV === 'dev') {
      this.enabled = true;
      this.log('[Logger]: in development environment, enabled by default');
    } else if (window.location.href.indexOf('logger=enabled') !== -1) {
      this.enabled = true;
      this.log('[Logger]: enabled by query parameter');
    }

    // of course, ie8 doesn't have Object.freeze
    try {
      Object.freeze(this.L);
    } catch (err) {
    }
  }

  applyPrefix(args) {
    if (this.prefix !== null) {
      if (typeof args[0] === 'string') {
        args[0] = this.prefix + args[0];
      } else {
        const out = []; const outSymbols = []; let i; const
          n = args.length;
        for (i = 0; i < n; i++) {
          switch (typeof args[i]) {
            case 'object':
              outSymbols.push('%o');
              break;
            default:
              outSymbols.push(' %s');
              break;
          }
          out.push(args[i]);
        }

        out.unshift(this.prefix + outSymbols.join(''));
        return out;
      }
    }
    return args;
  }

  log() {
    if (this.enabled) {
      this.L.log.apply(window.console, this.applyPrefix(arguments));
    }
  }

  info() {
    if (this.enabled) {
      this.L.info.apply(window.console, this.applyPrefix(arguments));
    }
  }

  warn() {
    if (this.enabled) {
      this.L.warn.apply(window.console, this.applyPrefix(arguments));
    }
  }

  error() {
    if (this.enabled) {
      this.L.error.apply(window.console, this.applyPrefix(arguments));
    }
  }

  setEnabled(value) {
    this.enabled = value;
  }

  disable() {
    this.setEnabled(false);
  }

  enable() {
    this.setEnabled(true);
  }

  setPrefix(p) {
    this.prefix = ((typeof p === 'undefined' || p === '') ? null : p);
  }
}

export default new Logger();
