import Logger from './util/logger';

/**
 * Responsible for loading the chunks that are required on the current page.
 * Required chunks are determined by testing for particular DOM elements.
 */
class ModuleLoader {

  /**
   * Module init tests
   */
  _modules() {
    return [
      {
        elem: '.js-accordion-container',
        module: 'modules/accordion'
      },
      {
        elem: '.js-tab-container',
        module: 'modules/tabs'
      },
      {
        elem: '.js-site-header',
        module: 'modules/nav'
      },
      {
        elem: '.js-eligibility-btn',
        module: 'modules/eligibility'
      },
      {
        elem: '.js-contact-form',
        module: 'modules/contact'
      },
      {
        elem: '.js-pricing-form',
        module: 'modules/pricing'
      },
      {
        elem: '.js-carousel-related',
        module: 'modules/slick-related'
      },
      {
        elem: '.js-zone-slider',
        module: 'modules/slick-country-pillar'
      },
      {
        elem: '#latest-news',
        module: 'modules/news'
      },
      {
        elem: 'body',
        module: 'modules/eligibility-message-listener'
      },
      {
        elem: '.section-paypal',
        module: 'modules/payment'
      },
      {
        elem: '.js-masonry',
        module: 'modules/masonry'
      },
      {
        elem: '.js-scrollbar',
        module: 'modules/scrollbar'
      },
      {
        elem: '.js-select',
        module: 'modules/dropdowns'
      },
      {
        elem: '.review--container',
        module: 'modules/reviews'
      },
      {
        elem: '.home-hero',
        module: 'modules/hero'
      },
      {
        elem: '.js-play-video',
        module: 'modules/video'
      },
      {
        elem: '.js-gallery-container',
        module: 'modules/gallery'
      }
    ];
  }

  /**
   * Executes tests and fetches required chunks
   */
  load() {
    const start = new Date();
    Logger.log(`[Module loader]::Detecting required modules...`);
    this._modules().forEach(module => {
      const elements = document.querySelectorAll(module.elem);
      if (elements.length) {
        Logger.log(`[Module loader]::Loading Module: ${module.module}`);
        import(`./${module.module}`);
      }
    });
    const end = new Date();
    Logger.log(`[Module loader]::Required module detection completed in ${end.getTime() - start.getTime()}ms`);
  }
}

export default new ModuleLoader();
